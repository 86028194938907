html {
  background-color: #DADADA;
  color: rgb(0, 0, 0);
  font-family: lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
}



img {
      max-width: 250px;
      max-height: 250px;
      padding: 15px;
}


/* size and orientation of panels */
/* grid has dimensions of 4 x 3 row x column  */
.panels {
  display: grid;
  grid-gap: 5px;
  padding-left: 5px;
  padding-right: 5px;
  grid-template-columns: 250px 900px auto ;
  grid-template-rows: 70px auto auto auto;
}

.panel-yearly {
  background-color: white;
}

.panel-pie {
  background-color: white;
}

/* takes up two columns */
.panel-header {
  grid-column: span 3;
  background-color: #1C4E80;
}

/* news panel takes up four rows */
.panel-news {
  grid-row: span 4;
  
}

/* our data filter will take up two rows */
.panel-filter {
  grid-row: span 2;
  background-color: white;
  
}

/* the update panel will have its own grid to hold the two pieces of data we have 
so that will take up two rows as well */
.panel-updates {
  grid-row: span 2;
  background-color: white;
}



/* text is aligned in the center */ 
.header-news {
  text-align: center;
  
}

/* height will be modified */
.container-news {
  display: list-item;
  overflow: auto;
  height: auto; 
  background-color: #1C4E80;
  color: white;
}

/* all news articles will be in grid display to have the photo of the article on the right
and the heading and news description on the right */
.article {
  display: grid;
  grid-gap: 5px 10px;
  grid-template-columns: auto auto;
  grid-row: auto auto;
  border-bottom: 1px solid black;
  padding: 10px;
  background-color: #f1f1f1;
  color: black;
}
.article
{
  
}
/* photo will take up two rows */
.article-photo {
  grid-row: span 2;
  size: 500px;
  text-align:left;
  
}
.article-description
{
  
}
img {
  
}
.container-news
{
  
}

/* this is the space inside the filterPanel to hold our design for data filtering */
.container-filter {
  display: grid;
  grid-template-rows: 80px auto;
  overflow: auto;
  height: 270px;
  
}

/* "OCR Wall Of Shame" title */
.filter-OCR {
  text-align: left;
  margin: 0;
  padding-top: 0;
  padding-bottom: 2px;
  border-bottom: 0.5px solid black;
}

/* first row which contains the checkboxes */
.container-filterCheckBox {
  text-align: center;
  padding: 15px;
  padding-bottom: 0px;
}

/* format for the check boxes all together */
.filterChBx {
  display: inline-block;
  left:-1em;
  right: auto;
  text-align:left;

}

.dropdown-btn {
  background-color: #1C4E80;
  color: white;
  padding: 10px;
  border: none;
}

.dropdown {
  position: relative;
}

.dropdown-items {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  color: black;
  min-width: 100%;
  max-height: 170px;
  padding-left: 0;
  overflow-y: auto;
  
}
ul.items {
  display: block;
  float: left;
  padding: 0px;
  margin: 0;
  border-top: none;
 
}

ul.items li {
  list-style:none;
  padding-left: 0;
}
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-items {display: block ;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropdown-btn {background-color: #3e8e41;}


.view-filter {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  grid-template-rows: 50px;
  /*grid-gap: 2px;*/
  padding: 0px;
  padding-bottom: 5px;
  padding-top: 2px;
}
.breach {
  grid-column: span 7;
  display: grid;
  grid-template-columns: 300px 50px 125px 50px 100px 150px 125px;
}


#view-filterHeader {
  padding-left: 8px;
  background-color: #f1f1f1;
  /*padding-right: 20px;
  border-right: 1px solid black;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: center;*/
}

.container-filterCheckBox{
  background-color: #1C4E80;
  color: white;
  justify-content: left;
}
.viewRow {
  border-right: 1px solid black;
  background-color: white;
}

#view-item {
  padding-left: 7px;
}

.container-yearly {
  display: grid;
  height: 350px;
}

.buttonHeader {
  text-align: center;
  height: 30px;
  padding: 20px;
  background-color: #1C4E80;
}

.button1 {
  background-color: #1C4E80;
  text-align: center;
  text-decoration: dotted;
  border: 1px;
  display: inline-block;
  padding: 10px;
  width: 100px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 15px;
  color: white;
}

.button1:hover {
  background-color: #3e8e41;
}
.yrly-bars {
  height: 250px;
  overflow: auto;
  padding-left: 20px;
  text-align: center;
}

.container-pie {
  display: grid;
  text-align: center;
  color: white;
}
.breach-title {
  background-color: #1C4E80;
  height: 60px;
  text-align: center;
  padding-top: 10px;
}
.pieGraph {
  padding-top: 30px;
  padding-left: 30px;
  text-align: center;
}
.container-updates {
  text-align: center;
}

.updates-unit {
  text-align: center;
  padding: 13px;
  background-color: #1C4E80;
  color: white;
}
.updates-item {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto;
  padding: 20px;
}
/* these will be modified later for more accuracy */ 
/* adjustments for window display */
@media (max-width: 750px) {
  .panels {
      grid-template-columns: 240px auto;
      grid-template-rows: auto auto auto auto;
  }

  .panel-header {
      grid-column: span 1;
  }
}

@media (max-width: 500px) {
  .panels {
      grid-template-columns: auto;
  }

  .panel-header {
      grid-column: span 1;
  }
}